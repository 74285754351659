<template>
  <div>
    <HMModalHeader
      :title="$t('STUDIO.TITLE.LEAVE_STUDIO_A_TIP', { studioName: studioName })"
      @close="$attrs._close()"
      v-if="!inFinishedStage"
    />
    <div v-if="inSelectStage">
      <div class="row" v-if="currentStudio">
        <div class="col-4 text-center">
          <div
            class="jar py-2"
            :class="{ active: jarSelected == 1 }"
            @click="selectOption(currentStudio.tip_jar_1_amount, 1)"
          >
            <div>
              <img src="/assets/images/settings/tip_jar_empty.svg" />
            </div>
            <div class="h5 mt-1">
              {{
                $formatCurrency(currentStudio.tip_jar_1_amount || 0, currency)
              }}
            </div>
          </div>
        </div>
        <div class="col-4 text-center">
          <div
            class="jar py-2"
            :class="{ active: jarSelected == 2 }"
            @click="selectOption(currentStudio.tip_jar_2_amount, 2)"
          >
            <div>
              <img src="/assets/images/settings/tip_jar_half_full.svg" />
            </div>
            <div class="h5 mt-1">
              {{
                $formatCurrency(currentStudio.tip_jar_2_amount || 0, currency)
              }}
            </div>
          </div>
        </div>
        <div class="col-4 text-center">
          <div
            class="jar py-2"
            :class="{ active: jarSelected == 3 }"
            @click="selectOption(currentStudio.tip_jar_3_amount, 3)"
          >
            <div>
              <img src="/assets/images/settings/tip_jar_full.svg" />
            </div>
            <div class="h5 mt-1">
              {{
                $formatCurrency(currentStudio.tip_jar_3_amount || 0, currency)
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <HMTextInput
            :label="$t('STUDIO.LABELS.CUSTOM_TIP')"
            type="number"
            v-model="customTipAmount"
            @update:modelValue="setCustomTipAmount"
            rules="min_value:1"
            name="custom_tip"
          >
            <template #prepend>
              {{ currency }}
            </template>
          </HMTextInput>
        </div>
      </div>
      <div>
        <HMButton
          class="col-12"
          color="brand"
          :disabled="!tipAmount"
          @click="goToPayTip()"
        >
          <div class="col">
            {{ tipButtonText }}
          </div>
        </HMButton>
      </div>
    </div>
    <div v-if="inConfirmStage">
      <Form ref="form" v-slot="{ meta }" @submit="submitTip">
        <HMTextInput
          v-if="userIsAnonymous"
          type="email"
          :label="$t('GENERAL.LABEL.EMAIL')"
          name="anon_user_email"
          rules="required"
          v-model="anonUserEmail"
        />
        <label class="mt-1" for="card-element">
          {{ $t('SIGNUP.LABEL.CREDIT_CARD') }}
        </label>
        <div
          id="card-element"
          ref="card-element"
          :class="{ 'is-invalid': stripeError }"
        ></div>
        <div class="invalid-feedback" v-if="stripeError">
          {{ stripeError }}
        </div>
        <HMButton
          class="col-12 mt-4"
          color="brand"
          :disabled="!stripeCompleted || inProgress || !meta.valid"
          type="submit"
          :loading="inProgress"
        >
          <div class="col">
            {{ tipButtonText }}
          </div>
        </HMButton>
      </Form>
      <div class="col mt-4 d-flex justify-content-end">
        <span>{{ $t('PRODUCTS.LABELS.CHECKOUT_BY') }}</span>
        <img
          class="img-stripe ms-2"
          src="/assets/images/Stripe.png"
        />
      </div>
    </div>
    <div v-if="inFinishedStage" class="text-center">
      <img
        class="mt-5 mb-3"
        src="/assets/images/settings/thank_you_heart.svg"
      />
      <h3>{{ $t('GENERAL.TEXT.THANK_YOU') }}</h3>
      <HMButton color="outline-gray" class="mt-4 mb-3" @click="$attrs._close()">
        <div class="col">
          {{ closeButtonText }}
        </div>
      </HMButton>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { style } from '@/utils/stripe';
import { errorToMessage } from '@/utils/errors';
import { Form } from 'vee-validate';
import { useStudioStore, useUserStore } from '@/stores';
import i18n from '@/utils/localization';

export default {
  name: 'TipJarModal',
  components: { Form },
  data() {
    return {
      stripeCompleted: false,
      stripeError: false,
      inProgress: false,
      jarSelected: null,
      tipAmount: null,
      customTipAmount: null,
      tipSuccessful: false,
      modalStage: null,
      anonUserEmail: null,
    };
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
    }),
    ...mapState(useUserStore, {
      role: 'role',
      studioURL: 'studioURL',
    }),
    tipButtonText() {
      return this.tipAmount
        ? this.$t('STUDIO.BUTTON.TIP_X_AMOUNT', {
            tipAmount: this.$formatCurrency(this.tipAmount || 0, this.currency),
          })
        : this.$t('STUDIO.BUTTON.SELECT_AN_AMOUNT');
    },
    userIsAnonymous() {
      return this.role === 'anonymous' || this.role === null;
    },
    inSelectStage() {
      return this.modalStage === this.STAGE_SELECT;
    },
    inConfirmStage() {
      return this.modalStage === this.STAGE_CONFIRM;
    },
    inFinishedStage() {
      return this.modalStage === this.STAGE_FINISHED;
    },
    currentStudio() {
      if (this.studio) {
        return this.studio;
      } else {
        try {
          return useStudioStore().getStudio(this.studioURL);
        } catch (e) {
          console.log(e);
          return null;
        }
      }
    },
  },
  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
    studioName: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    closeButtonText: {
      type: String,
      default() {
        return i18n.t('GENERAL.BUTTON.CLOSE');
      },
    },
  },
  methods: {
    selectOption(tipAmount, jarNumber) {
      this.jarSelected = jarNumber;
      this.tipAmount = tipAmount;
    },
    setCustomTipAmount() {
      console.log('setCustomTipAmount', this.customTipAmount);
      this.tipAmount = parseFloat(this.customTipAmount);
      this.jarSelected = 0;
    },
    goToPayTip() {
      if (this.clickable) {
        this.initStripe();
        this.modalStage = this.STAGE_CONFIRM;
      } else {
        return;
      }
    },
    stripeChangeCallback(event) {
      if (event.complete) {
        this.stripeCompleted = true;
        this.stripeError = false;
      } else if (event.error) {
        this.stripeCompleted = false;
        this.stripeError = event.error.message;
      }
    },
    initStripe() {
      this.stripe = window.Stripe(this.currentStudio.stripe_publishable_key);
      const elements = this.stripe.elements();
      this.card = elements.create('card', {
        style,
        hidePostalCode: true,
      });
      this.card.addEventListener('change', this.stripeChangeCallback);
      const mountTimer = setInterval(
        () => {
          if (this.$refs['card-element']) {
            this.card.mount(this.$refs['card-element']);
            clearInterval(mountTimer);
          }
        },
        50,
        100
      );
    },
    async submitTip() {
      this.inProgress = true;
      const reCaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY;
      let reCaptchaToken = await window.grecaptcha.execute(reCaptchaKey, {
        action: 'tipsetupintent',
      });

      const tipPayload: any = {
        tipAmount: this.tipAmount,
        studioURL: this.currentStudio?.studio_url,
      };
      try {
        const setupIntent = await useStudioStore().createTipSetupIntent({
          studioURL: this.currentStudio?.studio_url,
          payload: {
            anon_user_email: this.anonUserEmail,
            token: reCaptchaToken,
          },
        });
        const stripeCustomerId = setupIntent.stripe_customer_id;

        try {
          const confirmCard = await this.stripe.confirmCardSetup(
            setupIntent.setup_intent.client_secret,
            { payment_method: { card: this.card } }
          );
          if (!confirmCard.setupIntent) {
            this.$notify({
              group: 'app',
              type: 'error',
              title: 'Stripe card authorization failed',
              duration: -1, // stay ON
            });
            this.inProgress = false;
            return;
          }
          tipPayload.defaultPaymentMethod =
            confirmCard.setupIntent.payment_method;
          tipPayload.stripeCustomerId = stripeCustomerId;
        } catch (error) {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Stripe card authorization failed',
            text: errorToMessage(error.response, `Request failed: ${error}`),
            duration: -1, // stay ON
          });
          this.inProgress = false;
          return;
        }
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Stripe error',
          text: errorToMessage(error.response, `Request failed: ${error}`),
          duration: -1, // stay ON
        });
        this.inProgress = false;
        return;
      }
      // send data to BE and finish tip transaction there
      try {
        reCaptchaToken = await window.grecaptcha.execute(reCaptchaKey, {
          action: 'sendtip',
        });
        tipPayload.reCaptchaToken = reCaptchaToken;
        const tipResult = await useStudioStore().completeTip(tipPayload);
        this.tipSuccessful = true;
        this.inProgress = false;
        this.modalStage = this.STAGE_FINISHED;
        this.$notify({
          group: 'app',
          type: 'success',
          title: 'Success',
          text: this.$t('SETTINGS.TEXT.TIP_SUCCESSFUL'),
        });
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Stripe error',
          text: errorToMessage(error.response, `Request failed: ${error}`),
          duration: -1, // stay ON
        });
      }
      this.inProgress = false;
    },
  },
  created: function () {
    this.STAGE_SELECT = 'select';
    this.STAGE_CONFIRM = 'confirm';
    this.STAGE_FINISHED = 'finished';
    this.modalStage = this.STAGE_SELECT;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.jar {
  border: 1px solid $light-gray;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    border-color: var(--brand-color, $coral);
  }
  &.active {
    background-color: var(--brand-color-with-opacity-03, $light-bubblegum);
    border-color: var(--brand-color, $coral);
  }
}
.img-stripe {
  height: 22px;
  width: auto;
}
</style>
